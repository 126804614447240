import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'

// Loading
const textureLoader = new THREE.TextureLoader();
//const normalTexture = textureLoader.load('/textures/NormalMap_1.png')


// Debug
//const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.second')

// Scene
const scene = new THREE.Scene()

// Objects
const geometry = new THREE.TetrahedronBufferGeometry(1);
geometry.rotateZ(180)

// Materials
const material = new THREE.MeshStandardMaterial()
material.metalness = 1
material.roughness = 0.0
material.diffuse = 0.0
material.transparent = true
material.opacity = 0.5
    //material.normalMap = normalTexture
material.color = new THREE.Color(0x292929)

// Mesh
const tet = new THREE.Mesh(geometry, material)

scene.add(tet)
    // Lights

const pointLight = new THREE.PointLight(0xffffff, 0.1)
pointLight.position.x = 2
pointLight.position.y = 3
pointLight.position.z = 4
scene.add(pointLight)

const pointLight2 = new THREE.PointLight(0xff0000, 2)
pointLight2.position.set(0, -2, -1)
pointLight2.intensity = 3
scene.add(pointLight2)

const pointLight3 = new THREE.PointLight(0x7457d, 2)
pointLight3.position.set(0, 1.5, -1)
pointLight3.intensity = 5
scene.add(pointLight3)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 2
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */

document.addEventListener('mousemove', onDocumentMouseMove)

let mouseX = 0
let mouseY = 0

let targetX = 0
let targetY = 0

const windowHalfX = window.innerWidth / 2;
const windowHalfY = window.innerHeight / 2;


function onDocumentMouseMove(event) {
    mouseX = (event.clientX - windowHalfX)
    mouseY = (event.clientY - windowHalfY)
}

// const updateTet = event => {
//     tet.position.y = window.scrollY * -.0005;
// };

// window.addEventListener('scroll', updateTet);

const clock = new THREE.Clock()

const tick = () => {
    targetX = mouseX * .001
    targetY = mouseY * .001

    const elapsedTime = clock.getElapsedTime()

    // Update objects
    tet.rotation.y = .3 * elapsedTime
    tet.rotation.z = .5 * elapsedTime
        // Update Orbital Controls
        // controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()